.Contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact-para{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-para p{
    font-size: 20px;
    width: 80%;
    text-align: center;
}

.contact-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    margin: 20px 0px;
}

.section-grid{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-color);
    border: 1px solid var(--black-color);
    height: 300px;
    width: 60%;
    border-radius: 5px;
    padding: 10px;
}

.section-grid span{
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color);
}

.section-grid a{
    color: var(--font-color);
}

.section-line{
    height: 2px;
    width: 80%;
    background-color: var(--font-color);
}

.section-grid p{
    text-align: center;
    color: var(--font-color);
}

.contact-social{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.social-tags a{
    color: var(--main-color);
    font-size: 50px;
    margin: 0px 10px;
}

.contact-map{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 550px;
    border-radius: 10px;
    background-color: var(--font-color);
    margin: 20px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.mapp{
    border: none;
}

@media (max-width: 1000px) {
    .contact-grid{
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }
}

@media (max-width: 450px) {
    .section-grid{
        width: 90%;
    }
    .contact-map{
        width: 90%;
    }
}