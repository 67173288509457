.Login{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 100%;
    gap: 10px;
    padding: 10px 0px;
}

.login-left{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.loginlogo{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
}

.loginlogo img{
    height: 100px;
}

.loginnav{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
}

.loginnav span{
    font-size: 30px;
    font-weight: 600;
}

.login-input{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
    gap: 5px;
}

.login-input input{
    height: 40px;
    width: 400px;
    padding: 0px 0px 0px 10px;
    border-radius: 5px;
    border: 1px solid var(--main-color);
}

.loginnav button{
    text-decoration: none;
    outline: none;
    width: 95%;
    text-align: center;
    margin: 10px 0px;
    background-color: var(--main-color);
    color: var(--font-color);
    border-radius: 5px;
    padding: 10px;
}

.or{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    gap: 10px;
}

.orline{
    height: 2px;
    width: 180px;
    background-color: var(--main-color);
}

.loginheader{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
}

.google{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 76%;
}

.loginheader button{
    text-decoration: none;
    width: 95%;
    text-align: center;
    margin: 5px 0px;
    background-color: var(--main-color);
    color: var(--font-color);
    border-radius: 5px;
    padding: 10px;
    outline: none;
    border: none;
}

.loginfooter{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
}

.logintags{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--black-color);
    gap: 5px;
}

.logintags a{
    text-decoration: none;
    color: var(--black-color);
}

.donthave{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 0px;
}

.donthave span{
    font-size: 26px;
    font-weight: 500;
}

.donthavetags{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 10px 0px;
}

.donthavetags a{
    color: var(--main-color);
    text-decoration: none;
    font-size: 20px;
    margin: 5px 0px;
}

.dontpolicy{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.dontpolicy a{
    margin: 5px 0px;
    font-size: 14px;
    color: var(--main-color);
    text-decoration: none;
}

.login-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-right img{
    height: 100%;
    width: 100%;
}

@media (max-width: 1000px) {
    .Login{
        grid-template-columns: repeat(1, 1fr);
    }
    .login-right{
        padding: 20px;
    }
}