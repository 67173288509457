.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0px;
}

.nav-logo img {
  height: 60px;
}

.nav-links ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  list-style: none;
}

.nav-links a {
  display: inline-block;
  position: relative;
  color: var(--main-color);
  text-decoration: none;
  margin: 0px 20px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid rgba(128, 128, 128, 0.311);
  padding: 5px 10px;
  border-radius: 5px;
}

.nav-links a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--main-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-links a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* .nav-btns a:first-child{
  text-decoration: none;
  margin: 0px 10px;
  background-color: var(--main-color);
  color: var(--font-color);
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
}

.nav-btns a:first-child:hover{
  background-color: var(--font-color);
  color: var(--main-color);
}

.nav-btns a:last-child{
  text-decoration: none;
  margin: 0px 10px;
  background-color: var(--font-color);
  color: var(--main-color);
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
}

.nav-btns a:last-child:hover{
  background-color: var(--main-color);
  color: var(--font-color);
} */

.navbar-smallscreen {
  display: none;
}

.navbar-menu {
  font-size: 32px;
  cursor: pointer;
}

.navbar-smallscreen_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--main-color);
  color: var(--font-color);
  transition: 0.5s ease;
  flex-direction: column;
  z-index: 5;
}

.navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--font-color);
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 20px;
}

.navbar-smallscreen_links {
  list-style: none;
}

.navbar-smallscreen_links li {
  margin: 30px 0px;
}

.navbar-smallscreen_links a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem;
  cursor: pointer;
  color: var(--font-color);
  font-size: 2rem;
  text-align: center;
  text-decoration: none;
  user-select: none;
}

/* .nav-small-btns a{
  text-decoration: none;
  margin: 0px 10px;
  background: transparent;
  color: var(--font-color);
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid var(--font-color);
}

.nav-small-btns a:hover{
  background-color: var(--font-color);
  color: var(--main-color);
} */

.nav-line {
  height: 2px;
  width: 90%;
  background-color: var(--font-color);
  margin: 30px 0px;
}

/* Dropdown Button */
.dropbtn {
  background-color: var(--main-color);
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid var(--main-color);
  margin: 0px 5px;
  
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: var(--font-color);
  color: var(--main-color);}

@media screen and (max-width: 1050px) {
  .navbar {
    justify-content: space-between;
    margin: 0px 20px;
  }

  .nav-btns{
    display: none;
  }

  .nav-links {
    display: none;
  }

  .navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 300px) {
  .navbar {
    overflow-x: hidden;
  }
}
