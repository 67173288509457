.howitworks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #dceaf8;
  padding: 30px 0px;
}

.howitworks-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  place-items: center;
  gap: 20px;
  margin: 30px 0px;
}

.howitworks-grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70%;
  place-items: center;
  gap: 20px;
}

.works-grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.works-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.works-img img {
  height: 100px;
}

.works-content span1 {
  color: var(--main-color);
  font-size: 22px;
  margin: 5px 0px;
}

.works-content span {
  font-size: 28px;
}

.works-content p {
  width: 80%;
  text-align: center;
}

@media (max-width: 1190px) {
  .howitworks-grid{
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }
  .howitworks-grid1{
    width: 90%;
  }
}

@media (max-width: 850px) {
  .howitworks-grid{
    grid-template-columns: repeat(1, 1fr);
  }
  .howitworks-grid1{
    grid-template-columns: repeat(1, 1fr);
  }
  .works-content{
    text-align: center;
  }
}
