.Footer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    place-items: center;
    background-color: var(--main-color);
    padding: 20px 0px;
}

.footer-main{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 320px;
}
.footer-main1{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 320px;
}

.img-logo{
    margin: 0px 0px 20px 0px;
}

.img-logo img{
    height: 100px;
}

.f-logo a{
    text-decoration: none;
    color: var(--font-color);
}

.social h2{
    color: var(--font-color);
}

.social-links a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0px 20px 0px 0px;
    font-size: 22px;
}

.footer-tags{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.footer-tags a{
    text-decoration: none;
    color: var(--font-color);
    margin: 0px 0px 20px 0px;
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid var(--main-color);
}

.footer-tags a:hover{
    border-bottom: 2px solid var(--font-color);
}

.f-heading span{
    font-size: 24px;
    color: var(--font-color);
    font-weight: 600;
    border-bottom: 2px solid var(--font-color);
}

.link{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.link a{
    text-decoration: none;
    color: var(--font-color);
    margin: 15px 0px;
    border-bottom: 1px solid var(--main-color);
}

.link a:hover{
    border-bottom: 1px solid var(--font-color);
}

@media (max-width: 600px) {
    .Footer{
        grid-template-columns: repeat(1, 1fr);
        
        padding: 20px 0px;
    }
    .footer-main{
        width: 90%;
    }
    .footer-main1{
        margin: 20px 0px;
        width: 90%;
    }
    .footer-tags{
        width: 100%;
    }
   
}