.resumeform{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
}

.formm, .formmm{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
}

.resumeinputs{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 10px 0px;
    width: 100%;
}

.formm input{
    width: 100%;
    height: 20px;
    padding: 10px;
}

.resumeinputs span{
    width: 100%;
    text-align: right;
}

.formmm button{
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    color: var(--font-color);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.formmm button:hover{
    background: transparent;
    color: var(--main-color);
}

@media (max-width: 500px) {
    .resumeinputs{
        flex-direction: column;
    }
    .resumeinputs span{
        text-align: left;
    }
}