.Institutions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}

.reach{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 20px 0px;
}

.international-reach{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
}

.reach-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 20px;
    width: 90%;
    margin: 20px 0px;
}

.grid-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.grid-container img{
    height: 150px;
    margin-bottom: 10px;
}

.grid-container span{
    text-align: center;
    font-size: 30px;
}

.easyuse{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.easytouse-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.easytouse-img img{
    width: 100%;
    margin: 10px 0px;
}

.work-form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 30px 0px;
}

.partnership{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 90%;
}

.workform-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.workform-content span1{
    font-size: 24px;
    font-weight: 500;
    color: var(--main-color);
}

.workform-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.workform-heading span{
    font-size: 60px;
    font-weight: 600;
}

.workform-para{
    display: flex;
    justify-content: center;
    align-items: center;
}

.workform-para p{
    font-size: 20px;
}

.partnership-form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    place-items: center;
    gap: 20px;
}

form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.inputt{
    width: 100%;
    height: 30px;
    margin: 5px 0px;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    padding: 0px 0px 0px 10px;
}

.checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
}

.checkbox input{
    height: 20px;
    width: 10%;
}

.partner-form p{
    text-align: justify;
}

.partner-form button{
    background-color: var(--main-color);
    color: var(--font-color);
    font-size: 24px;
    padding: 5px 15px;
    border: 2px solid var(--main-color);
    outline: none;
    border-radius: 5px;
}

.partner-form button:hover{
    background-color: transparent;
    color: var(--main-color);
}

@media (max-width: 750px) {
    .reach-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .partnership-form{
        grid-template-columns: repeat(1, 1fr);
    }
}