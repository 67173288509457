.Home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: var(--main-color); */
    height: 700px;
    width: 100%;
    position: relative;
  width: 100%;
  overflow: hidden;
}

.header-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    gap: 20px;
    position: relative;
  z-index: 1; /* Ensure content is above the video */
  color: white;
    
}

.header-content p{
    font-size: 50px;
    font-weight: 500;
}

.video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1; /* Place behind the content */
  }

.header-content span{
    font-size: 60px;
    font-weight: 600;
    color: var(--font-color);
}

.visa{
    text-decoration: none;
    background-color: var(--font-color);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--main-color);
    font-size: 22px;
    border: 2px solid var(--font-color);
}

.visa:hover{
    background-color: transparent;
    color: var(--font-color);
}

.header-tags{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.header-tags a{
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--font-color);
    font-size: 22px;
    border: 2px solid var(--font-color);
}

.header-tags a:hover{
    background-color: var(--font-color);
    color: var(--main-color);
}

.mainfeatures{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
    background-color: #dceaf8;
}

.features-heading span{
    font-size: 40px;
    font-weight: 500;
}

.features{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    place-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.feature{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    width: 80%;
    padding: 20px;
    height: 100px;
    border-radius: 20px;
}

.feature p{
    width: 100%;
    color: var(--font-color);
    font-size: 24px;
    text-align: center;
}

.support{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
}

.support-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.support-heading span{
    font-size: 40px;
    font-weight: 500;
    text-align: center;
}

.support-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    place-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.student-support{
    background-color: var(--main-color);
    width: 100%;
    height: 500px;
    color: var(--font-color);
    border: 2px solid var(--main-color);
    border-radius: 20px;
}

.support-img img{
    height: 250px;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
}

.support-content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px 10px;
    height: 220px;
}

.support-content span{
    font-size: 40px;
    font-weight: 500;
}

.support-content p{
    font-size: 17px;
    text-align: justify;
}

.support-content a{
    text-decoration: none;
    background-color: transparent;
    border: 2px solid var(--font-color);
    padding: 10px;
    color: var(--font-color);
    border-radius: 10px;
}

.support-content a:hover{
    background-color: var(--font-color);
    color: var(--main-color);
}

.help{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--main-color);
    padding: 20px 0px;
    color: var(--font-color);
}

.help-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    gap: 20px;
    padding: 20px 0px;
}

.video-help img{
    width: 100%;
}

.help-tag a{
    text-decoration: none;
    background-color: var(--font-color);
    padding: 10px;
    border: 2px solid var(--font-color);
    color: var(--main-color);
    border-radius: 10px;
}

.help-tag a:hover{
    background: transparent;
    color: var(--font-color);
}

.admission{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--font-color);
    padding: 30px 0px;
    color: var(--main-color);
}

.addmission-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    gap: 30px;
    color: var(--black-color);
}

.lists-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    place-items: center;
}

.list-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100px;
    width: 100%;
}

.icon{
    font-size: 40px;
    color: var(--main-color);
}

.list-wrapper span{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 55px;
    text-align: center;
}

.ad-tag a{
    text-decoration: none;
    background-color: var(--main-color);
    padding: 10px;
    border: 2px solid var(--main-color);
    color: var(--font-color);
    border-radius: 10px;
}

.ad-tag a:hover{
    background: transparent;
    color: var(--main-color);
}

@media (max-width: 1250px) {
    .support-section{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1000px) {
    .features{
        width: 90%;
    }
}

@media (max-width: 910px) {
    .support-section{
        grid-template-columns: repeat(1, 1fr);
    }
    .student-support{
        width: 350px;
    }
}

@media (max-width: 650px) {
    .features{
        grid-template-columns: repeat(1, 1fr);
    }
    .addmission-container{
        width: 90%;
    }
}

@media (max-width: 450px) {
    .lists-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .list-wrapper{
        margin: 20px 0px;
    }
    .header-tags{
        display: none;
    }
    .header-content span{
        font-size: 45px;
    }
    .support-section{
        width: 100%;
    }
    .support-content span{
        font-size: 35px;
    }
    .support-content p{
        font-size: 15px;
    }
    .student-support{
        width: 300px;
    }
}