.clients{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.client-background{
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../Assets/studentbanner.png);
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
}

.client-back{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 75%;
}

.client-back span1{
    background-color: var(--font-color);
    color: var(--main-color);
    padding: 5px 10px;
    border-radius: 20px;
}

.client-back span{
    color: var(--font-color);
    font-size: 40px;
    font-weight: 500;
}

.client-back p{
    width: 40%;
    font-size: 20px;
    color: var(--font-color);
}

.client-back a{
    text-decoration: none;
    background-color: var(--font-color);
    padding: 10px 20px;
    border-radius: 10px;
    color: var(--main-color);
}

.clients-features{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    gap: 10px;
    width: 100%;
    margin: 20px 0px;
}

.grid-clients{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
}

.img-clients{
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-clients img{
    height: 60px;
}

.clients-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 80px;
}

.clients-content span{
    font-size: 20px;
    font-weight: 500;
}

.studyjourney{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    background-color: var(--main-color);
    color: var(--font-color);
    height: 250px;
}

.journey{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.study-logo img{
    height: 100px;
}

.journey-line{
    height: 200px;
    width: 3px;
    background-color: var(--font-color);
}

.journey-heading{
    width: 50%;
}

.journey-heading span{
    font-size: 40px;
}

.journey-tag a{
    background-color: var(--font-color);
    color: var(--main-color);
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid var(--font-color);
}

.journey-tag a:hover{
    color: var(--font-color);
    background: transparent;
}

.dream-destination{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #dceaf8;
    margin: 20px 0px;
    padding: 20px 0px;
}

.destination{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.destination-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 80%;
    place-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.country-destination{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--font-color);
    height: 220px;
    width: 100%;
    border-radius: 10px;
}

.country-img img{
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0px 0px;
}

.country-span{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

.country-span span{
    font-size: 24px;
}

.services{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 20px 0px;
}

.support-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.services-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 90%;
    gap: 20px;
    margin: 20px 0px;
    padding: 10px;
}

.service-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #dceaf8;
    width: 100%;
    border-radius: 20px;
    border: 1px solid var(--main-color);
}

.service-img img{
    width: 100%;
    border-radius: 20px 20px 0px 0px;
}

.service-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px;
}

.service-content span{
    font-size: 20px;
    font-weight: 500;
}

.service-content a{
    text-decoration: none;
    background-color: var(--main-color);
    color: var(--font-color);
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main-color);
}

.service-content a:hover{
    background-color: transparent;
    color: var(--main-color);
}

@media (max-width: 1190px) {
    .studyjourney{
        width: 90%;
    }
    .destination-grid{
        width: 90%;
    }
    .destination-grid{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 950px) {
    .clients-features{
        grid-template-columns: repeat(2, 1fr);
    }
    .services-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 850px) {
    .studyjourney{
        height: 400px;
    }
    .journey{
        flex-direction: column;
        gap: 40px;
    }
    .journey-line{
        display: none;
    }
    .journey-heading{
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 650px) {
    .destination-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .clients-features{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 400px) {
    .destination-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .country-destination{
        height: 300px;
    }
    .studyjourney{
        height: 500px;
    }
    .client-back{
        width: 95%;
    }
    .client-back p{
        width: 80%;
    }
    .services-grid{
        grid-template-columns: repeat(1, 1fr);
    }
}