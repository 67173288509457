.Forgetpassword{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 370px;
}

.forget{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    height: 370px;
}

.fp-logo{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.fp-logo img{
    height: 100px;
}

.main-forget{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.main-forget span{
    font-size: 30px;
    font-weight: 600;
}

.main-forget p{
    font-size: 24px;
}

.main-forget input{
    height: 40px;
    width: 100%;
    padding: 0px 0px 0px 10px;
    border-radius: 10px;
    border: 1px solid var(--main-color);
}

.reset{
    text-decoration: none;
    width: 100%;
    text-align: center;
    margin: 10px 0px;
    background-color: var(--main-color);
    color: var(--font-color);
    border-radius: 5px;
    padding: 10px;
}

.backto{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--black-color);
}