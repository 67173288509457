.Partners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.partner-main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    place-items: center;
    padding: 20px 0px;
}

.partner-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
    /* height: 500px; */
    padding: 0px 20px;
}

.partner-content span{
    font-size: 60px;
    font-weight: 500;
}

.partner-content p{
    line-height: 20px;
    font-size: 18px;
}

.partner-content a{
    background-color: var(--main-color);
    color: var(--font-color);
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
}

.partner-content a:hover{
    background-color: transparent;
    color: var(--main-color);
}

.partner-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.partner-img img{
    width: 100%;
}

.growth{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #dceaf8;
    padding: 20px 0px;
}

.growth-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    place-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.growth-grid1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 60%;
    place-items: center;
    gap: 20px;
    margin: 20px 0px;
}

.grid-grow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.growth-img img{
    height: 50px;
}

.growth-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.growth-content span1{
    font-size: 13px;
}

.growth-content p{
    font-size: 14px;
}

.commissions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-color);
    padding: 20px 0px;
    width: 100%;
    color: var(--font-color);
}

.perk-commissions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    padding: 20px;
}

.support-para{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.support-para p{
    font-size: 24px;
}

.perk-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    place-items: center;
    gap: 20px;
}

.perk{
    background-color: var(--font-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    color: var(--black-color);
    padding: 20px;
    border-radius: 20px;
}

.img-perk img{
    width: 100%;
}

.perk-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    text-align: center;
}

.perk-content span{
    font-size: 28px;
    font-weight: 500;
}

.easyuse{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
    width: 100%;
}

.easytouse{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
}

.easy-platform{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    margin: 30px 0px;
    place-items: center;
}

.easy-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.i{
    font-size: 50px;
    margin-bottom: 20px;
    color: var(--main-color);
}

.easy-grid span{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.easy-grid p{
    text-align: center;
}

.ecosystem{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #dceaf8;
    padding: 20px 0px;
}

.ecosystem-caring{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 75%;
    place-items: center;
    gap: 30px;
    margin: 20px 0px;
}

.ecosystem-grid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--font-color);
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.eco-img img{
    height: 200px;
    width: 200px;
}

.eco-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
}

.eco-content span{
    font-size: 24px;
    font-weight: 500;
}

.eco-content p{
    width: 90%;
    font-size: 15px;
    line-height: 25px;
}

.ecosystem-tag{
    margin: 20px 0px;
}

.ecosystem-tag a{
    text-decoration: none;
    background-color: var(--main-color);
    color: var(--font-color);
    padding: 10px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
}

.ecosystem-tag a:hover {
    background-color: transparent;
    color: var(--main-color);
}

.relationship{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--main-color);
    padding: 30px 0px;
    color: var(--font-color);
}

.testo-slider{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 20px 0px;
}

.testo-img{
    margin: 0px 20px 0px 0px;
}

.testo-img img{
    height: 300px;
    width: 300px;
}

.testo-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    text-align: justify;
}

.testo-content p{
    font-size: 20px;
    line-height: 30px;
}

.testo-content p::first-letter{
    font-size: 25px;
    font-weight: 600;
}

.testo-content span{
    font-size: 25px;
    font-weight: 500;
}

.testo-content span::first-letter{
    font-size: 35px;
    font-weight: 600;
}

.global-presence{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #dceaf8;
    padding: 30px 0px;
}

.global{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 75%;
}

.global-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.span-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 45%;
}

.span-container span{
    font-size: 50px;
    font-weight: 500;
    color: var(--main-color);
}

.span-container p{
    font-size: 18px;
}

.global-img{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.global-img img{
    width: 100%;
}

.last-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0px;
}

.contact-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
}

.container-span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.container-span span{
    font-size: 40px;
    font-weight: 500;
}

.container-tag{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
}

.container-tag a{
    text-decoration: none;
    background-color: var(--main-color);
    color: var(--font-color);
    padding: 15px;
    font-size: 22px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
}

.container-tag a:hover{
    background: transparent;
    color: var(--main-color);
}

.wrapper-stat{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 75%;
    place-items: center;
    gap: 20px;
    margin: 30px 0px 20px 0px;
}

.stat-grid{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 60%;
    height: 200px;
}

.stats{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 90px;
}

.stats img{
    height: 50px;
    margin: 0px 0px 10px 0px;
}

.stats span{
    font-size: 26px;
}

.stats-para{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100px;
    text-align: center;
}

.stats-para p{
    font-size: 18px;
}

@media (max-width: 1250px) {
    .partner-content span{
        font-size: 50px;
    }
    .ecosystem-caring{
        width: 90%;
    }
}

@media (max-width: 1170px) {
    .perk-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .ecosystem-caring{
        width: 98%;
    }
}

@media (max-width: 1000px) {
    .ecosystem-caring{
        grid-template-columns: repeat(1, 1fr);
    }
    .ecosystem-grid{
        width: 90%;
    }
    .testo-img{
        display: none;
    }
}

@media (max-width: 850px) {
    .partner-content span{
        font-size: 30px;
    }
    .partner-content p{
        font-size: 15px;
    }
    .growth-grid{
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
    }
    .growth-grid1{
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
    }
    .grid-grow{
        flex-direction: column;
    }
    .growth-content{
        align-items: center;
    }
    .growth-content p{
        text-align: center;
    }
}

@media (max-width: 770px) {
    .perk-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .global{
        flex-direction: column-reverse;
        gap: 20px;
    }
    .span-container{
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 650px) {
    .partner-main{
        grid-template-columns: repeat(1, 1fr);
    }
    .easy-platform{
        grid-template-columns: repeat(1, 1fr);
    }
    .i{
        margin-bottom: 10px;
    }
    .easy-grid{
        margin: 20px 0px;
    }
    .contact-container{
        flex-direction: column;
        gap: 20px;
    }
    .container-span{
        width: 100%;
        background-color: aqua;
    }
    .container-span span{
        text-align: center;
    }
    .container-tag{
        justify-content: center;
    }
    .wrapper-stat{
        grid-template-columns: repeat(1, 1fr);
    }
    .stat-grid{
        width: 80%;
    }
}

@media (max-width: 550px) {
    .ecosystem-grid{
        flex-direction: column;
    }
    .eco-content{
        align-items: center;
        width: 90%;
    }
    .eco-content p{
        text-align: center;
    }
}

@media (max-width: 450px) {
    .ecosystem-grid{
        width: 70%;
    }
    .eco-content span{
        font-size: 20px;
    }
    .perk-commissions{
        width: 80%;
    }
    .perk{
        width: 80%;
    }
}