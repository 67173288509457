.Career{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.difference{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color);
    width: 100%;
    color: var(--font-color);
    gap: 20px;
    height: 150px;
}

.difference-logo img{
    height: 80px;
}

.difference-span span{
    font-size: 30px;
}

.difference-tag a{
    background-color: var(--font-color);
    color: var(--main-color);
    padding: 10px;
    text-decoration: none;
    border: 2px solid var(--font-color);
}

.difference-tag a:hover{
    background-color: transparent;
    color: var(--font-color);
}

.perk-benefits{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
}

.benefits-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
}

.pb-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 30px;
    margin: 10px 0px;
}

.pb-grid1{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 30px;
    margin: 10px 0px;
}

.gridperks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 400px;
    border: 2px solid rgba(128, 128, 128, 0.414);
    width: 350px;
}

.gridi{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
}

.gridp{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 200px;
}

.gridperks img{
    width: 90%;
    height: 200px;
}

.gridperks span{
    font-size: 24px;
    font-weight: 500;
}

.gridperks p{
    text-align: center;
}

@media (max-width: 1170px) {
    .pb-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 775px) {
    .pb-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .pb-grid1{
        grid-template-columns: repeat(1, 1fr);
    }
    .difference{
        flex-direction: column;
        height: 250px;
    }
}

@media (max-width: 450px) {
    .difference{
        height: 300px;
    }
    .difference-span{
        width: 90%;
    }
    .difference-span span{
        text-align: center;
    }
    .gridperks{
        width: 80%;
    }
}