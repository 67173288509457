.privacypolicy{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    /* width: 90%; */
    padding: 0px 50px;
    text-align: left;
}

.privacypolicy span{
    font-size: 30px;
    font-weight: 500;
    color: var(--main-color);
}

.privacypolicy span1{
    font-size: 22px;
    font-weight: 400;
}

.privacypolicy p{
    width: 90%;
}