.Signup{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    place-items: center;
    margin: 20px 0px;
}

.signupimg img{
    height: 700px;
}

.signup-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f8f9fe;
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.signup-google{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signup-section p{
    font-size: 16px;
    color: rgb(133, 132, 132);
}

.fb-google a{
    text-align: center;
    background-color: var(--font-color);
    padding: 10px;
    text-decoration: none;
    margin: 0px 20px;
    color: var(--main-color);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.signupline{
    height: 2px;
    width: 100%;
    background-color: rgb(133, 132, 132);
    margin: 30px 0px;
}

.signupform{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px;
}

.signupf{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.signupf{
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    outline: none;
    border: none;
    padding: 0px 0px 0px 10px;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.signup-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.signupform button{
    text-decoration: none;
    width: 95%;
    text-align: center;
    margin: 10px 0px;
    background-color: var(--main-color);
    color: var(--font-color);
    border-radius: 5px;
    padding: 10px;
}

.signup-bottom{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
}

.already{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.already a{
    text-decoration: none;
    color: var(--main-color);
}

.signup-tags{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    text-decoration: none;
}

.signup-tags a{
    text-decoration: none;
    color: var(--main-color);
}

@media (max-width: 1000px) {
    .Signup{
        grid-template-columns: repeat(1, 1fr);
        
    }
    .login-right{
        padding: 20px;
    }
}