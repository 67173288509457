.getstarted{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 0px;
    background-color: #dceaf8;
}

.getstarted-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    place-items: center;
    gap: 20px;
}

.get-grid{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    width: 100%;
    height: 480px;
    /* border: 2px solid var(--main-color); */
    margin: 20px 0px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.getcontent{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    height: 250px;
}

.getcontent span{
    font-size: 24px;
    font-weight: 500;
}

.get-img img{
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
}

.getcontent p{
    font-size: 16px;
    text-align: center;
}

.getcontent a{
    text-decoration: none;
    background-color: var(--main-color);
    color: var(--font-color);
    border: 2px solid var(--main-color);
    padding: 10px;
    border-radius: 10px;
}

.getcontent a:hover{
    background: transparent;
    color: var(--main-color);
}

@media (max-width: 1000px) {
    .getstarted-grid{
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
    .get-grid{
        width: 40%;
    }
    .get-img img{
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .get-grid{
        width: 60%;
    }
    .get-img img{
        width: 100%;
    }
}