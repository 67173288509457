.leadership{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.leader-main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 80%;
    padding: 20px 0px;
}

.leader-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 80%;
}

.leader-content span{
    font-size: 35px;
    font-weight: 500;
}

.leader-content p{
    font-size: 18px;
}

.leader-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.leader-img img{
    width: 100%;
}

.jointeam{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin: 10px 0px;
    height: 100px;
    background-color: #dceaf8;
    width: 100%;
}

.joinspan{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    text-align: center;
}

.joinspan span{
    font-size: 40px;
}

.joincontact{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.joincontact a{
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    color: var(--font-color);
    background-color: var(--main-color);
    border: 2px solid var(--main-color);
    font-weight: 500;
}

.joincontact a:hover{
    background-color: transparent;
    color: var(--main-color);
}

@media (max-width: 1190px) {
    .leader-main{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 575px) {
    .jointeam{
        flex-direction: column;
        height: 200px;
    }
}