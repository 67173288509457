.About{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.our-story{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dceaf8;
    padding: 30px;
}

.ourstory-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
}

.osimg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100px;
    width: 100px;
    margin: 10px 0px;
    border-radius: 50%;
    background-color: var(--main-color);
    border: 2px solid var(--font-color);
}

.osimg img{
    height: 50px;
}

.ourstory-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 4;
    margin: 0px 20px;
}

.ourstory-content span{
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.ourstory-content p{
    font-size: 20px;
}

.ourstory-content a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    color: var(--main-color);
}

.ourvalues{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
    width: 100%;
}

.ourvalues-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 90%;
    gap: 20px;
    margin: 20px 0px;
}

.value-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
}

.value-grid span{
    font-size: 18px;
}

.ourvalues img{
    width: 100%;
    border-radius: 80px;
}

.fastfacts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px 0px;
}

.factsgrid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    width: 100%;
}

.fastgrid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fastfacts img{
    height: 100px;
}

.fastgrid span{
    font-size: 40px;
    font-weight: 600;
}

.fastgrid p{
    font-size: 20px;
}

@media (max-width: 1000px) {
    .our-story{
        flex-direction: column;
    }
    .ourvalues-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .factsgrid{
        grid-template-columns: repeat(2, 1fr);
    }
    .ourstory-img{
        display: none;
    }
}

@media (max-width: 650px) {
    .ourvalues-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .factsgrid{
        grid-template-columns: repeat(1, 1fr);
    }
}