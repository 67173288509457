@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html{
  scroll-behavior: smooth;
}

:root {
  --main-color: #0e5094;
  --grey-color: #edf3f5;
  --green-color: #00cc99;
  --font-color: #feffff;
  --black-color: #17252a;
}

/* *{
  outline: 1px solid red;
} */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--font-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  pointer-events: none;
}